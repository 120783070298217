import React from 'react';
import { useEffect, useRef, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Slide, Fade, Button } from '@mui/material';

import { Helmet } from 'react-helmet';
import SMG1 from '../assets/images/sorm-mg-1.png';
import SMG2 from '../assets/images/sorm-mg-2.png';
import SMG3 from '../assets/images/sorm-mg-3.png';
import SMG4 from '../assets/images/sorm-mg-4.png';
import SMG5 from '../assets/images/sorm-mg-5.png';
import SMG6 from '../assets/images/sorm-mg-6.png';
import SMG7 from '../assets/images/sorm-mg-7.png';
import SMG8 from '../assets/images/sorm-mg-8.png';
import SMG10 from '../assets/images/sorm-mg-10.png';
import SMG11 from '../assets/images/sorm-mg-11.png';
import SMG12 from '../assets/images/sorm-mg-12.png';
import PDF from '../assets/SORM-Mix&Go-dolum-makinesi.pdf';

function SormMixAndGo() {
	const theme = useTheme();

	const data = [
		{
			icon: MATERIEL_ICONS.Devices,
			text: '7" Dokunmatik Renkli TFT LCD Ekran',
			iconColor: '#000',
			dotColor: '#ffc300',
		},
		{
			icon: MATERIEL_ICONS.Report,
			text: 'Günlük Rapor Sayfası SCADA sistemine entegre edilebilir',
			iconColor: '#000',
			dotColor: '#ffc300',
		},
		{
			icon: MATERIEL_ICONS.Warning,
			text: 'Bilgilendirme ve uyarı ekranları, Farklı reçeteler oluşturabilme',
			iconColor: '#000',
			dotColor: '#ffc300',
		},
		{
			icon: MATERIEL_ICONS.Settings,
			text: 'Servis için 7/24 uzaktan bağlantı hizmeti',
			iconColor: '#000',
			dotColor: '#ffc300',
		},
		{
			icon: MATERIEL_ICONS.TrendingUp,
			text: 'Hassas Loadcelli tartım sistemi, Artırılabilir sıvı adedi',
			iconColor: '#000',
			dotColor: '#ffc300',
		},
	];

	const specifications = [
		{ text: 'PLC kontrollü' },
		{ text: 'SCADA sistemine entegre edilebilme' },
		{ text: 'Cep telefonu ile uzaktan kontrol edebilme' },
		{ text: 'Şebeke gerilimi 380 V' },
		{ text: 'Farklı reçete kaydetme, yükleme, değiştirme' },
		{ text: 'Sağlam, dayanıklı 304 paslanmaz çelik gövde tasarım' },
		{ text: '7’’ Dokunmatik Renkli TFT LCD Ekran' },
		{ text: 'Servis için 7/24 uzaktan bağlantı hizmeti' },
		{ text: 'Kullanıcı dostu, anlaşılır ara yüzlü yazılım' },
		{ text: 'Bilgilendirme ve uyarı ekranları' },
		{ text: '‘’Aktif Değer’’ bölmesinden geçen sıvıyı anlık takip edebilme' },
		{
			text: 'Otomatik modda akışı bekletme/durdurma özelliği ile kaldığı yerden devam edebilme',
		},
		{ text: 'Program kalibrasyon özelliği' },
		{ text: 'Solenoid ve pnömatik valf sistemleri' },
		{ text: 'Günlük rapor sayfası' },
		{ text: 'Güvenlik için şifreli yetkili operatör girişi' },
		{ text: 'Direkt olarak tesisata/tanka bağlantı kolaylığı' },
		{ text: 'Sesli ve ışıklı hata ve uyarı bildirimleri' },
		{ text: 'Kısa devre, aşırı yük ve aşırı gerilim koruma' },
		{ text: 'Hassas Loadcellli tartım sistemi' },
	];

	const features = [
		{
			// title: 'Reçete Tanımlama',
			title: 'Verimlilik',
			description:
				'Reçetelerin otomatik yönetimi ile zaman tasarrufu sağlar. İhtiyaç duyulan dolum miktarları ve sıvı bileşenleri, cihazın TFT ekranı üzerinden kolaylıkla reçete olarak kaydedilebilir. ',
		},
		{
			// title: 'Otomatik Dolum ve Aktarım',
			title: 'Hassas Ölçüm ve Kontrol',
			description:
				'İnsan hatalarını minimuma indirir. Dolum işlemi başlatıldığında, sistem her sıvıyı sırayla ölçerek miksere otomatik olarak aktarır.',
		},
		{
			title: 'Tam Entegrasyon',
			description:
				'Dolum, karışım ve transfer süreçlerinin otomatik koordinasyonu. Reçetenin miksere eksiksiz aktarımı tamamlandığında, sistem miksere start komutunu otomatik olarak gönderir.',
		},
		{
			title: 'Esnek Reçete Yönetimi',
			description:
				'Farklı karışımlar için birden fazla reçete oluşturulabilir ve kaydedilebilir.',
		},
		{
			title: 'Transfer Otomasyonu',
			description:
				'Karışım işleminin ardından, transfer pompasına otomatik olarak start komutu iletilerek karışımın taşınması sağlanır.',
		},
	];

	const advantages = [
		'Hassas Ölçüm ve Kontrol: İnsan hatalarını minimuma indirir.',
		'Verimlilik: Reçetelerin otomatik yönetimi ile zaman tasarrufu sağlar.',
		'Esneklik: Kullanıcı dostu arayüz ve çoklu reçete desteği.',
		'Tam Entegrasyon: Dolum, karışım ve transfer süreçlerinin otomatik koordinasyonu.',
	];

	const [currentImage, setCurrentImage] = useState(0);
	const [currentImage2, setCurrentImage2] = useState(0);
	const images = [
		SMG1,
		SMG2,
		SMG3,
		SMG4,
		SMG5,
		SMG6,
		SMG7,
		SMG8,
		SMG10,
		SMG11,
		SMG12,
	];
	const images2 = [
		SMG1,
		SMG2,
		SMG3,
		SMG4,
		SMG5,
		SMG6,
		SMG7,
		SMG8,
		SMG10,
		SMG11,
		SMG12,
	];

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage((prevImage) => (prevImage + 1) % images.length);
		}, 3000); // Change image every 3 seconds
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage2((prevImage) => (prevImage + 1) % images2.length);
		}, 5000); // Change image every 3 seconds
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Helmet>
				<title>SORM Mix and Go - Tartımlı Karışım ve Dozajlama Makinesi</title>
				<meta
					name='description'
					content='SORM Mix&Go sıvı dolum makinesi, kolay kullanım ve yüksek performans sunar. Su, alkol, deterjan ve madeni yağların istenilen ölçüde otomatik olarak verilmesini sağlar.'
				/>
				<meta
					name='keywords'
					content='SORM Mix&Go, sıvı dolum makinesi, otomatik dolum, endüstriyel dolum makinesi, sıvı kontrol cihazı, alkol dolum, deterjan dolum, madeni yağ dolum'
				/>
			</Helmet>
			<div className='w-full flex flex-col justify-center items-center bg-[#f7f7f7] text-black'>
				<div className='flex flex-col justify-center items-center w-full h-screen max-lg:h-full relative'>
					<img
						src={SMG1}
						alt='Sorm Mix&Go'
						className='w-full h-full object-cover'
					/>
					<div className='sm:absolute sm:bottom-0 sm:right-0 p-4'>
						<Button
							href={PDF}
							target='_blank'
							rel='noreferrer'
							variant='outlined'
							startIcon={<MATERIEL_ICONS.Download />}
							style={{
								fontWeight: 'bold',
								color: 'black',
								border: '2px solid black',
							}}
						>
							SORM Mix&Go Katalog
						</Button>
					</div>
				</div>
				<div className='max-w-[1280px] max-sm:hidden'>
					<Timeline
						position='alternate'
						sx={{
							marginTop: '2rem',
							marginBottom: '2rem',
						}}
					>
						{data.map((el, index) => (
							<Slide
								key={index}
								direction={index % 2 === 0 ? 'right' : 'left'}
								in={true}
								timeout={index * 600}
								mountOnEnter
								unmountOnExit
							>
								<TimelineItem key={index}>
									<TimelineOppositeContent
										sx={{ m: 'auto 0' }}
										align='right'
										variant='body2'
										color='text.secondary'
									>
										<Typography
											variant='h6'
											style={{
												color: 'black',
												fontWeight: 'bold',
												fontSize: '1.5rem',
											}}
										>
											{el.text}
										</Typography>
									</TimelineOppositeContent>

									<TimelineSeparator>
										<TimelineDot sx={{ backgroundColor: el.dotColor }}>
											<el.icon sx={{ color: el.iconColor, fontSize: '2rem' }} />
										</TimelineDot>
										<Fade
											key={index}
											in={true}
											timeout={10000}
											mountOnEnter
											unmountOnExit
										>
											<TimelineConnector
												style={{
													width: '5px',
													backgroundColor: el.dotColor,
													borderRadius: '5px',
												}}
											/>
										</Fade>
									</TimelineSeparator>
									<TimelineContent></TimelineContent>
								</TimelineItem>
							</Slide>
						))}
					</Timeline>
				</div>
				<div className='sm:hidden'>
					<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 my-4'>
						{data.map((spec, index) => (
							<Fade in={true} timeout={index * 1500} mountOnEnter unmountOnExit>
								<li key={index} className='flex items-center gap-4'>
									<spec.icon />
									<p className=' text-black'>{spec.text}</p>
								</li>
							</Fade>
						))}
					</ul>
				</div>
				<div className='max-w-[1280px] w-full flex flex-col justify-center items-center'>
					<Fade
						direction='down'
						in={true}
						timeout={5000}
						mountOnEnter
						unmountOnExit
					>
						<div className='w-full  flex flex-col justify-center items-center p-8  text-black'>
							<h1 className='text-[72px] font-bold text-center'>SORM Mix&Go</h1>
							<h2
								className='text-[32px] text-center'
								style={{ fontWeight: '400' }}
							>
								Tartımlı Karışım
							</h2>
							<h3
								className='text-[28px] text-center'
								style={{ fontWeight: '400' }}
							>
								Dozajlama Makinesi
							</h3>
						</div>
					</Fade>
					<div className='flex flex-col justify-center items-center w-full h-screen max-lg:h-full'>
						<img
							src={SMG3}
							alt='Sorm Mix&Go'
							className='w-full h-full object-cover'
						/>
					</div>
					<div className='flex flex-wrap justify-center items-center w-full max-w:[1280px]  mt-4'>
						<div className='w-full p-3 m-3 text-left'>
							{/* <h2 className='text-[36px] max-sm:text-[24px] font-bold text-center text-black'>
								Kısaca{' '}
								<span className='bg-[#01b0f1] text-white'>SORM Mix&Go</span>
							</h2> */}
							<p className='text-justify text-2xl'>
								İstenilen dolum miktarı, istenen ölçülerde her bir sıvı için
								reçete olarak kaydedilir. Ekran üzerinden dolum başlatıldığında,
								her bir sıvı sırayla ölçülerek miksere aktarılır. Farklı
								reçeteler oluşturulabilmektedir. Reçete aktarımı tamamlanınca
								miksere start komutu gönderilir. Ayrıca karışım sonrası transfer
								pompasına da start komutu verir.
							</p>
						</div>
					</div>
					<div className='w-full  flex flex-col justify-center items-center p-8  text-black'>
						<h2 className='text-[36px] max-sm:text-[24px] font-bold text-center text-black'>
							Sunduğu Avantajlar
						</h2>
					</div>
					<div className='flex flex-wrap justify-center items-center w-full max-w:[1280px]  mt-4'>
						<div className='w-full p-3 m-3 text-left'>
							<h2 className='text-[36px] max-sm:text-[24px] font-bold text-left text-black'>
								{features[0].title}
								{/* <span className='bg-[#01b0f1] text-white'>SORM Mix&Go</span> */}
							</h2>
							<p className='text-left text-2xl'>{features[0].description}</p>
						</div>
					</div>
					<div className='flex flex-col justify-center items-center w-full h-screen max-lg:h-full'>
						<img
							src={SMG2}
							alt='Sorm Mix&Go'
							className='w-full h-full object-cover'
						/>
					</div>
					<div className='flex flex-wrap justify-center items-center w-full max-w:[1280px]  mt-4'>
						<div className='w-full p-3 m-3 text-left'>
							<h2 className='text-[36px] max-sm:text-[24px] font-bold text-right text-black'>
								{features[1].title}
								{/* <span className='bg-[#01b0f1] text-white'>SORM Mix&Go</span> */}
							</h2>
							<p className='text-right text-2xl'>{features[1].description}</p>
						</div>
					</div>
					<div className='flex flex-col justify-center items-center w-full h-screen max-lg:h-full'>
						<img
							src={SMG5}
							alt='Sorm Mix&Go'
							className='w-full h-full object-cover'
						/>
					</div>
					<div className='flex flex-wrap justify-center items-center w-full max-w:[1280px]  mt-4'>
						<div className='w-full p-3 m-3 text-left'>
							<h2 className='text-[36px] max-sm:text-[24px] font-bold text-left text-black'>
								{features[2].title}
								{/* <span className='bg-[#01b0f1] text-white'>SORM Mix&Go</span> */}
							</h2>
							<p className='text-left text-2xl'>{features[2].description}</p>
						</div>
					</div>
					<div className='flex flex-col justify-center items-center w-full h-screen max-lg:h-full'>
						<img
							src={SMG6}
							alt='Sorm Mix&Go'
							className='w-full h-full object-cover'
						/>
					</div>

					<div className='flex flex-col  justify-center items-center w-full max-w:[1280px] bg-[#f7f7f7] mt-8 pt-4'>
						<h1 className='text-[36px] font-bold text-center text-black'>
							Başlıca Özellikler
						</h1>
						<div className='flex flex-row max-md:flex-wrap justify-center items-center w-full mt-4'>
							<ul className='w-full flex flex-col justify-center items-start text-left gap-2 p-4 text-black'>
								{specifications
									.slice(0, Math.ceil(specifications.length / 2))
									.map((spec, index) => (
										<li key={index} className='flex items-center gap-4'>
											<MATERIEL_ICONS.Done />
											<p>{spec.text}</p>
										</li>
									))}
							</ul>

							<ul className='w-full flex flex-col justify-center items-start text-left gap-2 p-4 text-black'>
								{specifications
									.slice(Math.ceil(specifications.length / 2))
									.map((spec, index) => (
										<li
											key={index + Math.ceil(specifications.length / 2)}
											className='flex items-center gap-4'
										>
											<MATERIEL_ICONS.Done />
											<p>{spec.text}</p>
										</li>
									))}
							</ul>
						</div>
					</div>
					{/* <div className='flex '>
						<img
							src={images[currentImage]}
							alt='Sorm Mix&Go'
							loading='lazy'
							className='w-full h-full object-cover'
						/>
					</div> */}

					<div className='flex flex-col justify-center items-center mt-8 p-4'>
						<h5 className='text-center'>
							Faaliyetlerimizle ilgili her türlü soruyu bize sormaktan
							çekinmeyin. Size cevap vermekten mutluluk duyarız.
						</h5>
						<p className='text-center'>+90 532 390 72 78</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default SormMixAndGo;
