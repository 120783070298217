import React from 'react';
import RASTLogo from '../assets/images/logos/rast-logo.png';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { ExpandMore, Menu } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import SM from '../assets/images/sm-featured.png';
import SB from '../assets/images/sb-rem-bg.png';
import SMG from '../assets/images/sorm-mg-10-cropped.png';

function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
	const [expandMore, setExpandMore] = useState(false);
	const theme = useTheme();
	const location = useLocation();

	const menu_items = [
		{
			name: 'Sıvı Dolum Sistemleri',
			link: 'sivi-dolum-makineleri',
			toDiv: false,
			children: [
				{
					name: 'SORM Master',
					link: 'sorm-master',
					img: SM,
					toDiv: false,
				},
				{
					name: 'SORM Basic',
					link: 'sorm-basic',
					img: SB,
					toDiv: false,
				},
				{
					name: 'SORM Mix&Go',
					link: 'sorm-mixandgo',
					img: SMG,
					toDiv: false,
				},
			],
		},
		{
			name: 'Çalışma Alanlarımız',
			link: 'calisma-alanlarimiz',
			toDiv: true,
		},
		{
			name: 'Hakkımızda',
			link: 'hakkimizda',
			toDiv: true,
		},
		{
			name: 'Bize Ulaşın',
			link: 'bize-ulasin',
			toDiv: true,
		},
	];

	const allMenuItems = [
		{
			name: 'Sorm Master',
			link: 'sorm-master',
			toDiv: false,
		},
		{
			name: 'Sorm Basic',
			link: 'sorm-basic',
			toDiv: false,
		},
		{
			name: 'Sorm Mix&Go',
			link: 'sorm-mixandgo',
			toDiv: false,
		},
		...menu_items,
	];

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	useEffect(() => {
		if (location.hash) {
			const element = document.querySelector(location.hash);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [location]);

	const transparentHeaderUrls = [
		'/sorm-basic',
		'/sorm-master',
		'/sorm-mixandgo',
	];

	const textBlackUrls = ['/sorm-mixandgo'];

	return (
		<>
			<header
				className={`flex w-full max-sm:justify-between max-sm:items-center max-sm:p-4 h-24 py-6 px-20 z-10 
					${transparentHeaderUrls.includes(location.pathname) ? 'absolute top-0' : ''}
					${textBlackUrls.includes(location.pathname) ? 'text-black' : 'text-white'}
				`}
			>
				<div className='flex w-1/2 '>
					<a className='font-bold text-[32px]' href='/'>
						<img src={RASTLogo} alt='RAST Logo' className='h-12' />
					</a>
				</div>
				<div className='flex w-1/2 max-2xl:hidden relative'>
					<div className='flex w-full justify-start items-center'>
						<ul className='flex gap-4 h-12 justify-center items-center'>
							{menu_items.map((item, index) => (
								<li
									className='h-full flex justify-center items-center'
									key={index}
								>
									<a
										href={
											location.pathname === '/' && item.toDiv
												? `#${item.link}`
												: item.toDiv
												? `/#${item.link}`
												: `/${item.link}`
										}
										className={`h-full flex justify-center items-center font-medium cursor-pointer px-3 py-2 `}
									>
										{item.name}
									</a>
									<i>
										{item.children && (
											<ExpandMore
												style={{
													color: textBlackUrls.includes(location.pathname)
														? 'black'
														: 'white',
													cursor: 'pointer',
												}}
												onClick={() => setExpandMore(!expandMore)}
											/>
										)}
									</i>
								</li>
							))}
						</ul>
					</div>

					<div
						className={`${
							expandMore ? 'block' : 'hidden'
						} absolute top-14 left-0 z-10 bg-[#0d1117d0] w-[650px]
						`}
					>
						<Collapse in={expandMore} timeout={1000} unmountOnExit>
							<ul className='w-full h-full flex  gap-2 m-2 p-2'>
								{menu_items.map((item, index) =>
									item.children ? (
										<>
											{item.children.map((child, index) => (
												<li
													className='w-full flex justify-center items-center'
													key={index}
												>
													<a
														href={
															location.pathname === '/' && child.toDiv
																? `#${child.link}`
																: child.toDiv
																? `/#${child.link}`
																: `/${child.link}`
														}
														className={`h-full flex gap-2 justify-center items-center font-medium cursor-pointer px-3 py-2 
															hover:transform hover:scale-110 transition ease-in-out delay-150 duration-300 ${
																textBlackUrls.includes(location.pathname)
																	? 'text-white'
																	: 'text-white'
															}
															`}
													>
														{child.name}
														{child.img && (
															<img
																src={child.img}
																alt={child.name}
																className='w-10 h-max px-1'
															/>
														)}
													</a>
												</li>
											))}
										</>
									) : null,
								)}
							</ul>
						</Collapse>
					</div>
				</div>
				<div className='2xl:hidden sm:w-1/2 flex justify-end items-center z-10'>
					<div className='max-sm:block cursor-pointer' onClick={toggleMenu}>
						<IconButton
							style={{
								backgroundColor: theme.palette.navy.main,
							}}
							onClick={toggleMenu}
						>
							<Menu style={{ color: theme.palette.text.primary }} />
						</IconButton>
					</div>
				</div>
			</header>
			<Drawer
				variant={'temporary'}
				anchor={'right'}
				sx={{
					'& .MuiDrawer-paper': {
						width: '25%',
						boxSizing: 'border-box',
						border: 'none',
						backgroundColor: '#002060E2',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
						// [theme.breakpoints.between('md', 'xl')]: {
						//   display: 'none',
						// },
						[theme.breakpoints.down('md')]: {
							width: '100%',
						},
					},
				}}
				open={menuOpen}
				onClose={toggleMenu}
				onOpen={toggleMenu}
			>
				<div className='flex flex-col h-full justify-center items-center gap-8'>
					<div className='w-full p-2 m-2 flex justify-start items-center'>
						<IconButton onClick={toggleMenu}>
							<ChevronRightIcon style={{ color: theme.palette.text.primary }} />
						</IconButton>
					</div>
					<ul className='w-full h-full flex flex-col gap-2 m-2 p-2'>
						{allMenuItems.map((item, index) => (
							<li
								className='w-full flex justify-center items-center'
								key={index}
							>
								<a
									href={
										location.pathname === '/' && item.toDiv
											? `#${item.link}`
											: item.toDiv
											? `/#${item.link}`
											: `/${item.link}`
									}
									className={`h-full flex justify-center items-center font-medium cursor-pointer px-3 py-2 `}
								>
									{item.name}
								</a>
							</li>
						))}
					</ul>
				</div>
			</Drawer>
		</>
	);
}

export default Header;
