import React from 'react';
import Home from '../pages/Home';
import SormBasic from '../pages/SormBasic';
import SormMaster from '../pages/SormMaster/SormMaster';
import SormMixAndGo from '../pages/SormMixAndGo';
import Qr from '../pages/QR';
import LiquidFilling from '../pages/LiquidFilling';

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/sorm-basic',
    element: <SormBasic />,
  },
  {
    path: '/sorm-master',
    element: <SormMaster />,
  },
  {
    path: '/sorm-mixandgo',
    element: <SormMixAndGo />,
  },
  {
    path: '/mustafa-koyuncu',
    element: <Qr />,
  },
  {
    path: "sivi-dolum-makineleri",
    element: <LiquidFilling />,
  }

];

export default routes;
