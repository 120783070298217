import React from 'react';
import SM from '../assets/images/sm-featured.png';
import SB from '../assets/images/sb-rem-bg.png';
import SMG from '../assets/images/sorm-mg-10-cropped.png';
import HoverCard from '../components/HoverCard';
import { Collapse, Fade } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

const Questions = [
	{
		question: 'SORM Nedir?',
		answer:
			'	SORM, sıvı dolum / dozajlama makineleri, endüstriyel üretim süreçlerinde sıvıların hassas ve verimli bir şekilde doldurulması ve oranlanması için geliştirilmiş makinelerdir. Su, alkol, sıvı deterjan ve madeni yağlar gibi çeşitli sıvıların, belirli oranlarda ve hacimlerde otomatik olarak verilmesini sağlar. Özellikle dolum işlemlerinde yüksek hassasiyet ve kontrol imkanı sunarak hem zaman hem de maliyet tasarrufu sağlar.',
	},
	{
		question: 'Sıvı Dolum Makineleri Hangi Sektörlerde Kullanılır?',
		answer:
			'SORM makineleri, gıda, kozmetik, kimya, ilaç, temizlik ve otomotiv gibi sektörlerde sıvı dolum makineleri, üretim süreçlerinde sıvıların hassas bir şekilde doldurulması ve oranlanması için kullanılır.',
	},
	{
		question: 'Sıvı Dolum Makineleri Nerelerde Kullanılır?',
		answer:
			'SORM makineleri, sıvı dolum işlemlerinin yapıldığı birçok sektörde kullanılmaktadır. Gıda, kozmetik, kimya, ilaç, temizlik ve otomotiv gibi sektörlerde sıvı dolum makineleri, üretim süreçlerinde sıvıların hassas bir şekilde doldurulması ve oranlanması için kullanılır. SORM makineleri, sıvı dolum işlemlerinde yüksek hassasiyet ve kontrol imkanı sunarak hem zaman hem de maliyet tasarrufu sağlar.',
	},
	{
		question: 'Sıvı Dolum Makineleri Hangi Sıvıları Doldurabilir?',
		answer:
			'SORM sıvı dolum makineleri, su, sıvı gübre, zeytinyağı, asit, alkol, sıvı deterjan, madeni yağlar, kozmetik ürünler, ilaçlar ve gıda ürünleri gibi birçok farklı sıvıyı doldurabilir. ',
	},
	// {
	// 	question: 'Sıvı Dolum Makineleri Nasıl Çalışır?',
	// 	answer:
	// 		'SORM sıvı dolum makineleri, sıvı dolum işlemlerinde yüksek hassasiyet ve kontrol imkanı sunar. Makine, sıvı dolum işlemi sırasında sıvıyı belirli bir hacimde ve oranda doldurmak için tasarlanmıştır. Belirlenen hacim reçete olarak kay',
	// },
	{
		question: 'Harici Start-Stop Kontrolü ile Kontrol Edilebilir mi?',
		answer:
			'Evet, harici start-stop kontrolü ile kontrol edilebilir. (Sensör, Pedal, Buton vb.)',
	},
	{
		question: 'Zeytinyağı dolumu yapabilir mi?',
		answer: 'Evet, zeytinyağı dolumu yapabilir.',
	},
	{
		question: 'Sıvı gübre dolumu yapabilir mi?',
		answer: 'Evet, sıvı gübre dolumu yapabilir.',
	},
	{
		question: 'Deterjan dolumu yapabilir mi?',
		answer: 'Evet, deterjan dolumu yapabilir.',
	},
	{
		question: 'Paslanmaz çelikten mi üretilmiştir?',
		answer:
			'Evet, sıvıya göre temas eden tüm ekipmanları AISI 304 veya AISI 316 paslanmaz çelikten imal edilmektedir.',
	},
];

const Question = ({ question, answer }) => {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = () => {
		setExpanded((prev) => !prev);
	};

	return (
		<div className='p-2 cursor-pointer' onClick={handleChange}>
			<div className='flex flex-row justify-between items-center '>
				<h5 className='text-[20px] max-md:text-[18px] text-left '>
					{question}
				</h5>
				{expanded ? (
					<ExpandLess fontSize='large' />
				) : (
					<ExpandMore fontSize='large' />
				)}
			</div>

			<div className={expanded ? 'block' : 'hidden'}>
				<Collapse in={expanded} timeout='auto' unmountOnExit>
					<p
						className={`text-[18px] max-md:text-[14px] text-justify border-t-2 border-white `}
					>
						{answer}
					</p>
				</Collapse>
			</div>
		</div>
	);
};

function LiquidFilling() {
	return (
		<>
			<Helmet>
				<title>Sıvı Dolum Makineleri</title>
				<meta
					name='description'
					content='SORM sıvı dolum makineleri ile su, zeytinyağı, sıvı gübre, deterjan gibi farklı sıvıları hassas ve verimli şekilde doldurun. Endüstriyel dolum çözümleri için bizi ziyaret edin.'
				/>
				<meta
					name='keywords'
					content='sıvı dolum makineleri, endüstriyel sıvı dolum, SORM Master, SORM Basic, sıvı dolum, zeytinyağı dolum, deterjan dolum'
				/>
				<meta name='robots' content='index, follow' />
				<link
					rel='canonical'
					href='https://www.rastmakine.com/sivi-dolum-cihazlari'
				/>
				<meta
					property='og:title'
					content='Sıvı Dolum Makineleri | SORM Master ve Basic Modelleri'
				/>
				<meta
					property='og:description'
					content='Endüstriyel sıvı dolum makineleri ile çeşitli sıvıları hassas ve verimli şekilde doldurun. SORM Master ve Basic modellerini inceleyin.'
				/>
				<meta property='og:image' content={SM} />
				<meta
					property='og:url'
					content='https://www.rastmakine.com/sivi-dolum-cihazlari'
				/>
			</Helmet>

			<div className='w-full flex justify-center items-center '>
				<div className='max-w-[1280px] w-full'>
					<h1 className='text-[72px] max-md:text-[36px] text-center '>
						Sıvı Dolum Makineleri
					</h1>

					<div className='flex flex-wrap justify-center items-center'>
						<Fade
							direction={'right'}
							in={true}
							timeout={2500}
							mountOnEnter
							unmountOnExit
						>
							<div>
								<HoverCard text='SORM Master' img={SM} link='/sorm-master' />
							</div>
						</Fade>
						<Fade
							direction={'down'}
							in={true}
							timeout={2500}
							mountOnEnter
							unmountOnExit
						>
							<div>
								<HoverCard text='SORM Mix&Go' img={SMG} link='/sorm-mixandgo' />
							</div>
						</Fade>
						<Fade
							direction={'left'}
							in={true}
							timeout={2500}
							mountOnEnter
							unmountOnExit
						>
							<div>
								<HoverCard text='SORM Basic' img={SB} link='/sorm-basic' />
							</div>
						</Fade>
					</div>

					<h3 className='text-[32px] max-md:text-[20px] text-center underline'>
						Sıkça Sorulan Sorular
					</h3>

					{Questions.map((faq, index) => (
						<Question key={index} question={faq.question} answer={faq.answer} />
					))}
				</div>
			</div>
		</>
	);
}

export default LiquidFilling;
