import React, { useRef } from 'react';
import {
	motion,
	useMotionTemplate,
	useMotionValue,
	useSpring,
} from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const HoverCard = (props) => {
	return (
		<div className=' place-content-center px-4 py-12 text-slate-900'>
			<TiltCard img={props.img} text={props.text} link={props.link} />
		</div>
	);
};

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = 32.5 / 2;

const TiltCard = (props) => {
	const { img, text, link } = props;
	const ref = useRef(null);
	const navigate = useNavigate();

	const x = useMotionValue(0);
	const y = useMotionValue(0);

	const xSpring = useSpring(x);
	const ySpring = useSpring(y);

	const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

	const handleMouseMove = (e) => {
		if (!ref.current) return [0, 0];

		const rect = ref.current.getBoundingClientRect();

		const width = rect.width;
		const height = rect.height;

		const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
		const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

		const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
		const rY = mouseX / width - HALF_ROTATION_RANGE;

		x.set(rX);
		y.set(rY);
	};

	const handleMouseLeave = () => {
		x.set(0);
		y.set(0);
	};

	return (
		<motion.div
			ref={ref}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			style={{
				transformStyle: 'preserve-3d',
				transform,
			}}
			className='relative h-64 w-[380px]  rounded-xl bg-[#fff] shadow-lg'
		>
			<div
				style={{
					transform: 'translateZ(75px)',
					transformStyle: 'preserve-3d',
				}}
				className='absolute inset-4 grid place-content-center rounded-xl  cursor-pointer'
				onClick={() => navigate(link)}
			>
				<div className='flex flex-col justify-center items-center'>
					<img src={img} alt={text} className='w-24 text-center' />
					<p className='text-right text-2xl font-bold text-black'>{text}</p>
				</div>
			</div>
		</motion.div>
	);
};

export default HoverCard;
