import React, { useState, useRef, useEffect } from 'react';
import Tick from '../../assets/images/svg/tickcircle.svg';
import Button from '@mui/material/Button';
import { ArrowRightAlt } from '@mui/icons-material';
import SormMasterPin from '../../assets/images/sorm-master-pins.png';
import SormMasterFeatured from '../../assets/images/sm-featured.png';
import Slide from '@mui/material/Slide';
import './style.css';

const Tooltip = ({ text, position }) => {
	return (
		<div
			className={`absolute bg-[#d9d9d9] text-black text-sm rounded py-1 px-2 ${position}`}
			style={{ whiteSpace: 'nowrap' }}
		>
			{text}
		</div>
	);
};

const PulseIcon = (props) => {
	const { top, right, left, bottom, tooltipText } = props;
	const [showTooltip, setShowTooltip] = useState(false);
	const pulse = !showTooltip ? 'pulse pulse-icon' : '';

	return (
		<div
			className={`bg-[#ffffff83] w-10 h-10 rounded-full absolute ${pulse}`}
			style={{ top, right, left, bottom }}
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			{showTooltip && (
				<Tooltip text={tooltipText} position='top-[-30px] left-[-30px]' />
			)}
		</div>
	);
};

function SMFeatured() {
	const items = [
		'Petrol pompası konforunda sıvı dolumu',
		'Zamanlı ve flowmetreli dolum modu',
		'Hızlı ve net ölçülerde dolum',
		'AISI 304 Paslanmaz Çelik',
		'Gıda, yağ veya kimyasallara uygun',
		'10 farklı reçete kaydedebilme, değiştirme ve silme',
	];

	const [inView, setInView] = useState(false);
	const containerRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					setInView(true);
					observer.disconnect(); // Stop observing after the animation is triggered
				}
			},
			{ threshold: 0.5 },
		);

		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			if (observer && observer.disconnect) {
				observer.disconnect();
			}
		};
	}, []);

	return (
		<div className='w-full flex justify-center items-center' ref={containerRef}>
			<div className='max-w-[1280px] flex-wrap-reverse w-full flex justify-between max-lg:justify-center items-center sm:p-24 max-sm:p-8 gap-8'>
				<div className='flex flex-col gap-8 max-lg:justify-center max-lg:items-center '>
					<h1 className='text-[72px] font-bold max-sm:text-center'>
						SORM Master
					</h1>
					<ul className='flex flex-col  gap-4'>
						{items.map((item, index) => (
							<li key={index} className='flex items-center gap-4'>
								<img src={Tick} alt='Tick' />
								<span>{item}</span>
							</li>
						))}
					</ul>
					<Button
						variant='contained'
						color='turquoise'
						endIcon={<ArrowRightAlt />}
						size='large'
						style={{ width: '200px' }}
						href='/sorm-master'
					>
						Detaylar
					</Button>
				</div>
				<div>
					<Slide
						direction={'left'}
						in={inView}
						timeout={2000}
						mountOnEnter
						unmountOnExit
					>
						<div>
							<div className='relative max-sm:hidden '>
								<PulseIcon top='36px' right='213px' tooltipText='TFT Ekran' />
								<PulseIcon
									bottom='150px'
									right='80px'
									tooltipText='Özel Tasarım Tabanca'
								/>
								<img src={SormMasterFeatured} alt='Sorm Master' />
							</div>

							<div className='relative sm:hidden '>
								<img src={SormMasterPin} alt='Sorm Master' />
							</div>
						</div>
					</Slide>
				</div>
			</div>
		</div>
	);
}

export default SMFeatured;
