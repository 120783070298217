import React, { useState, useEffect, useRef } from 'react';
import Tick from '../assets/images/svg/tickcircle.svg';
import Button from '@mui/material/Button';
import { ArrowRightAlt } from '@mui/icons-material';
import SMG from '../assets/images/sorm-mg-10-cropped.png';
import Slide from '@mui/material/Slide';

function SMGFeatured() {
	const items = [
		'Hassas Ölçüm ve Kontrol: İnsan hatalarını minimuma indirir.',
		'Verimlilik: Reçetelerin otomatik yönetimi ile zaman tasarrufu sağlar.',
		'Esneklik: Kullanıcı dostu arayüz ve çoklu reçete desteği.',
		'Tam Entegrasyon: Dolum, karışım ve transfer süreçlerinin otomatik koordinasyonu.',
	];

	const [inView, setInView] = useState(false);
	const containerRef = useRef(null);

	// useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		(entries) => {
	// 			if (entries[0].isIntersecting) {
	// 				setInView(true);
	// 				observer.disconnect(); // Stop observing after the animation is triggered
	// 			}
	// 		},
	// 		{ threshold: 0.5 },
	// 	);

	// 	if (containerRef.current) {
	// 		observer.observe(containerRef.current);
	// 	}

	// 	return () => {
	// 		if (observer && observer.disconnect) {
	// 			observer.disconnect();
	// 		}
	// 	};
	// }, []);

	return (
		<div className='w-full flex justify-center items-center' ref={containerRef}>
			<div className='max-w-[1280px] flex-wrap w-full flex justify-between max-lg:justify-center items-center sm:p-24 max-sm:p-8 gap-8'>
				<div>
					<Slide
						direction={'down'}
						in={true}
						timeout={2000}
						mountOnEnter
						unmountOnExit
					>
						<img src={SMG} alt='Sorm Mix&Go' className='w-[320px]' />
					</Slide>
				</div>
				<div className='flex flex-col gap-8 justify-end items-end max-lg:justify-center max-lg:items-center '>
					<h1 className='text-[72px] font-bold max-sm:text-center'>
						SORM Mix&Go
					</h1>
					<ul className='flex flex-col items-end gap-4'>
						{items.map((item, index) => (
							<li key={index} className='flex text-right items-end gap-4'>
								<span>{item}</span>
								<img src={Tick} alt='Tick' />
							</li>
						))}
					</ul>
					<Button
						variant='contained'
						color='turquoise'
						endIcon={<ArrowRightAlt />}
						size='large'
						style={{ width: '200px' }}
						href='/sorm-mixandgo'
					>
						Detaylar
					</Button>
				</div>
			</div>
		</div>
	);
}

export default SMGFeatured;
